import React from "react";
import "./New_User_Table.css"

function New_User_Table() {
  return (
    <>
      <section className="New_User_Table">
        <div className="table-frame-holder">
          <img
            className="table-frame-img"
            src={process.env.PUBLIC_URL + "/Frame/list-table-frame.png"}
            alt="table-frame-img"
          />
        </div>
      </section>
    </>
  );
}

export default New_User_Table;

import React from 'react'
import Profile_info from './Profile_info/Profile_info'
import Profile_Tab from './Profile_Tab/Profile_Tab'

function Profile() {
  return (
 <>
  <section className='Profile'>
    <div className='info-card-holder'>
      <Profile_info/>
      <Profile_Tab/>
    </div>
  </section>
 </>
  )
}

export default Profile
import React from 'react'
import "./Heading_Holder.css"

function Heading_Holder({headingText}) {
  return (
   <>
    <section className='Heading_Holder'>
        <h3 className='font-use'>{headingText}</h3>
    </section>
   </>
  )
}

export default Heading_Holder
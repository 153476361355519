import React, { useContext } from "react";
import "./Login.css";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import Border_btn from "../Common_Component/Border_btn/Border_btn";
import Star_Lottie from "../Common_Component/Star_Lottie/Star_Lottie";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import { LoginPost } from "../../utils/apis/Login";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function Login() {
  const { setSignin, setUsertype, setisAllow } = useContext(Context);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      // console.log(data);

      const result = await LoginPost(data);
      // console.log(result);

      if (result?.success) {
        setSignin(result.success);
        setUsertype(result?.data?.role);
        // setisAllow(result?.data?.permissions);

        Cookies.set("warri_sport_admin_security", result.data?.token, {
          expires: 1,
        });

        navigate("/dashboard");
      } else {
        setError("password", {
          message: result?.message,
        });
      }
      return;
    } catch (error) {
      console.error(error);
    }
  };

  const { signin, usertype } = useContext(Context);

  if (signin) {
    navigate("/dashboard");
  } else {
    return (
      <>
        <section className="Login">
          <div className="container-fluid container-holder">
            <div className="row main-content-holder  ">
              <div className="col-lg-8 col-md-12">
                <div className="main-Form-holder">
                  <div className="img-holder">
                    <img
                      className="bg-img"
                      src={process.env.PUBLIC_URL + " /Login/form-bg.png"}
                      alt="bg-img"
                    />
                  </div>
                  <div className="form-holder ">
                    <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="font-use">Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          className={classNames("", {
                            "is-invalid": errors?.email,
                          })}
                          {...register("email", {
                            required: "Email is required",
                          })}
                        />
                        {errors.email && (
                          <span className="text-danger">
                            {errors.email.message}
                          </span>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label className="font-use">Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          className={classNames("", {
                            "is-invalid": errors?.password,
                          })}
                          {...register("password", {
                            required: "Password is required",
                          })}
                        />
                        {errors.password && (
                          <span className="text-danger">
                            {errors.password.message}
                          </span>
                        )}
                      </Form.Group>

                      <section className="Border_btn">
                        <Button
                          className="sign-up-btn font-use"
                          type="button"
                          onClick={handleSubmit(onSubmit)}
                        >
                          Login
                          <span>
                            <FontAwesomeIcon icon={faCircleArrowRight} />
                          </span>
                        </Button>
                      </section>
                      {/* <Border_btn btnText={"Sign Up"} baseLink={"/dashboard"} /> */}
                    </Form>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-5">
                <div className="casino-img-holder">
                  <img
                    className="casino-img"
                    src={process.env.PUBLIC_URL + "/Login/casino-wheel.png"}
                    alt="casino-img"
                  />
                  {/* Place multiple instances of Star_Lottie here with different classes */}
                  {/* <div className="star-lottie top-left">
                    <Star_Lottie />
                  </div>
                  <div className="star-lottie bottom-center">
                    <Star_Lottie />
                  </div>
                  <div className="star-lottie top-right">
                    <Star_Lottie />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Login;

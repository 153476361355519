import {
    GET,
    POST,
    PUT,
    EditStatus,
    DELETE,
    DownloadExcel,
} from "../../../api";

export const DataList = async (currentPage, perPage, search, country_id, state_id, city_id) => {
    try {
        return await GET(`/master/pincode?page=${currentPage || 1}&per_page=${perPage || 10}&term=${search || ""}` +
            `&country_id=${country_id || ""}&state_id=${state_id || ""}&city_id=${city_id || ""}`
        );
    } catch (error) {
        console.log(error);
    }
};

export const SingleData = async (id) => {
    try {
        return await GET(`/master/pincode/${id}`);
    } catch (error) {
        console.log(error);
    }
};

export const DataAdd = async (data) => {
    try {
        return await POST(`/master/pincode`, data);
    } catch (error) {
        console.log(error);
    }
};

export const EditAdd = async (id, data) => {
    try {
        return await POST(`/master/pincode/${id}`, data);
    } catch (error) {
        console.log(error);
    }
};

export const ChangeStatus = async (id) => {
    try {
        return await EditStatus(`/master/pincode/${id}`);
    } catch (error) {
        console.log(error);
    }
};

export const DeleteData = async (id) => {
    try {
        return await DELETE(`/master/pincode/${id}`);
    } catch (error) {
        console.log(error);
    }
};

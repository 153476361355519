import React from "react";
import "./Delete_Points_Modal.css";
import { Button, Form, Modal } from "react-bootstrap";
import Modal_Heading from "../../Modal_Heading/Modal_Heading";
import Remove_Points_Modal_Frame from "./Delete_Points_Modal_Frame/Delete_Points_Modal_Frame";

function Delete_Points_Modal(props) {
  return (
    <>
      <section>
        <Modal
          {...props}
          className=" Add_Points_Modal modal_holder"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal_Heading modalHeading={"Delete Points"} />
          <div className="modal-body-holder">
            <Remove_Points_Modal_Frame />
            <Modal.Body>
              <div className="main-form-holder">
                <Form className="form-holder">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="delete-text-holder">
                        <p>Do you want to delete this points.</p>
                      </div>
                    </div>
                  </div>
                </Form>

                <div className="modal-footer-holder">
                  <div className="row">
                    <div className=" col-md-6 col-sm-6 col-6">
                      <Button className="search-btn" onClick={props.onHide}>
                        No
                      </Button>
                    </div>

                    <div className=" col-md-6 col-sm-6 col-6">
                      <Button className="reset-btn " type="button" onClick={props?.handleDeleteRecord}>
                        Yes
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </section>
    </>
  );
}

export default Delete_Points_Modal;

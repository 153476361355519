import React from "react";
import "./Profile_info.css";

function Profile_info() {
  return (
    <>
      <section className="Profile_info">
        <div className="row">
          <div className="col-lg-1 col-sm-6 col-6 profile-col-holder  ">
            <div className="profile-holder">
              <img
                className="profile-img"
                src={process.env.PUBLIC_URL + "/Login/casino-wheel.png"}
                alt="profile-img"
              />
            </div>
          </div>
          <div className="col-lg-4 col-sm-6  col-12 ">
            <div className="heading-holder">
              <h3>Name: kumar11_66</h3>
              <p>Email : kumar@gmail.com</p>
              <p>Contact No. : +91 3446738992</p>
            </div>
          </div>

          <div className="col-lg-7 col-md-12">
            <div className="row count-info-holder justify-content-end">
              <div className="col-md-3 col-6 border-holder">
                <div className="text-holder">
                  <div >
                    <h3>00</h3>

                    <p>Wallet Amount</p>
                  </div>
                </div>
              </div>


              <div className="col-md-3  col-6 border-holder">
                <div className="text-holder">
                  <div >
                    <h3>00</h3>

                    <p>Wallet Amount</p>
                  </div>
                </div>
              </div>


              <div className="col-md-3 col-6 border-holder">
                <div className="text-holder">
                  <div >
                    <h3>00</h3>

                    <p>Wallet Amount</p>
                  </div>
                </div>
              </div>


              <div className="col-md-3 col-6">
                <div className="text-holder">
                  <div >
                    <h3>00</h3>

                    <p>Wallet Amount</p>
                  </div>
                </div>
              </div>

              
            
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Profile_info;

import React, { useContext } from "react";
import { Context } from "./utils/context.js";
import { useNavigate } from "react-router-dom";
import Login from "./Component/Login/Login";

const Middleware = ({ component: Component }) => {
  // Destructure `signin` and `usertype` from the context
  const { signin, usertype } = useContext(Context);
  const navigate = useNavigate();

  // console.log(signin); // For debugging

  // If user is signed in, render the provided component
  if (signin) {
    return Component;
  } else {
    navigate("/");
    // If not signed in, render the Login component
    // return <Login />;
  }
};

export default Middleware;

import React from "react";
import "./Modal_Heading.css";

function Modal_Heading({ modalHeading }) {
  return (
    <>
      <section className="Modal_Heading">
        <h3 className="font-use">{modalHeading}</h3>
      </section>
    </>
  );
}

export default Modal_Heading;

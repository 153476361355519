import React from "react";
import "./Banner_View_List.css";
import { Button, Form, Table } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faMinusCircle,
  faPenToSquare,
  faPlay,
  faPlus,
  faRefresh,
  faRotateRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Banner_List_Table from "./Banner_List_Table/Banner_List_Table";
import Edit_User_Modal from "../../../Common_Component/Common_Modal/Edit_User_Modal/Edit_User_Modal";
import Add_Points_Modal from "../../../Common_Component/Common_Modal/Add_Points_Modal/Add_Points_Modal";
import Delete_Points_Modal from "../../../Common_Component/Common_Modal/Delete_Points_Modal/Delete_Points_Modal";
import Heading_Holder from "../../../Common_Component/Heading_Holder/Heading_Holder";

function Banner_View_List() {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalAddShow, setModalAddShow] = React.useState(false);
  const [modalDeleteShow, setModalDeleteShow] = React.useState(false);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "black" : "",
      color: state.isFocused || state.isSelected ? "white" : "white",
      cursor: "pointer",
    }),
  };
  return (
    <>
      <section className="View_List">
        <Heading_Holder headingText={"Banner List"} />

        <div className="search-bar-holder mt-4  mb-3">
          <Form>
            <div className="row">
              <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-5    ">
                <Form.Group className="form-field" controlId="formBasicEmail">
                  <Form.Control type="number" placeholder="User Mobile No." />
                </Form.Group>
              </div>

              <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-5    ">
                <Form.Group className="form-field" controlId="formBasicEmail">
                  <Form.Control type="text" placeholder="User Name" />
                </Form.Group>
              </div>

              <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-5   ">
                <Select
                  options={options}
                  styles={customStyles}
                  placeholder="-- Page Show--"
                />
              </div>

              <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-5  col-holder">
                <Button type="button" className="search-btn">
                  Search
                </Button>
              </div>

              <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-5   col-holder">
                <Button type="button" className="reset-btn">
                  Reset
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="user-list-table">
              <Banner_List_Table />

              <div className="data-table-holder">
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Name</th>
                      <th>Mobile Number</th>
                      <th>Password</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th className="border-none-holder">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>01</td>
                      <td>Rimsha Pethe</td>
                      <td>+91 1234567890</td>
                      <td>Pass@123</td>
                      <td className="text-red">₹4200</td>
                      <td>
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                      <td className="border-none-holder">
                        <div className="action-holder">
                          <div
                            className="icon-holder"
                            onClick={() => setModalShow(true)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalAddShow(true)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalDeleteShow(true)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>01</td>
                      <td>Rimsha Pethe</td>
                      <td>+91 1234567890</td>
                      <td>Pass@123</td>
                      <td className="text-red">₹4200</td>
                      <td>
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                        <td className="border-none-holder">
                        <div className="action-holder">
                          <div
                            className="icon-holder"
                            onClick={() => setModalShow(true)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalAddShow(true)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalDeleteShow(true)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>01</td>
                      <td>Rimsha Pethe</td>
                      <td>+91 1234567890</td>
                      <td>Pass@123</td>
                      <td className="text-red">₹4200</td>
                      <td>
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                        <td className="border-none-holder">
                        <div className="action-holder">
                          <div
                            className="icon-holder"
                            onClick={() => setModalShow(true)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalAddShow(true)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalDeleteShow(true)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>01</td>
                      <td>Rimsha Pethe</td>
                      <td>+91 1234567890</td>
                      <td>Pass@123</td>
                      <td className="text-red">₹4200</td>
                      <td>
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                        <td className="border-none-holder">
                        <div className="action-holder">
                          <div
                            className="icon-holder"
                            onClick={() => setModalShow(true)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalAddShow(true)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalDeleteShow(true)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>01</td>
                      <td>Rimsha Pethe</td>
                      <td>+91 1234567890</td>
                      <td>Pass@123</td>
                      <td className="text-red">₹4200</td>
                      <td>
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                        <td className="border-none-holder">
                        <div className="action-holder">
                          <div
                            className="icon-holder"
                            onClick={() => setModalShow(true)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalAddShow(true)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalDeleteShow(true)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>01</td>
                      <td>Rimsha Pethe</td>
                      <td>+91 1234567890</td>
                      <td>Pass@123</td>
                      <td className="text-red">₹4200</td>
                      <td>
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                        <td className="border-none-holder">
                        <div className="action-holder">
                          <div
                            className="icon-holder"
                            onClick={() => setModalShow(true)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalAddShow(true)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalDeleteShow(true)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>01</td>
                      <td>Rimsha Pethe</td>
                      <td>+91 1234567890</td>
                      <td>Pass@123</td>
                      <td className="text-red">₹4200</td>
                      <td>
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                        <td className="border-none-holder">
                        <div className="action-holder">
                          <div
                            className="icon-holder"
                            onClick={() => setModalShow(true)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalAddShow(true)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalDeleteShow(true)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>01</td>
                      <td>Rimsha Pethe</td>
                      <td>+91 1234567890</td>
                      <td>Pass@123</td>
                      <td className="text-red">₹4200</td>
                      <td>
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                        <td className="border-none-holder">
                        <div className="action-holder">
                          <div
                            className="icon-holder"
                            onClick={() => setModalShow(true)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalAddShow(true)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalDeleteShow(true)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>01</td>
                      <td>Rimsha Pethe</td>
                      <td>+91 1234567890</td>
                      <td>Pass@123</td>
                      <td className="text-red">₹4200</td>
                      <td>
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                        <td className="border-none-holder">
                        <div className="action-holder">
                          <div
                            className="icon-holder"
                            onClick={() => setModalShow(true)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalAddShow(true)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalDeleteShow(true)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>01</td>
                      <td>Rimsha Pethe</td>
                      <td>+91 1234567890</td>
                      <td>Pass@123</td>
                      <td className="text-red">₹4200</td>
                      <td>
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                        <td className="border-none-holder">
                        <div className="action-holder">
                          <div
                            className="icon-holder"
                            onClick={() => setModalShow(true)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalAddShow(true)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalDeleteShow(true)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>01</td>
                      <td>Rimsha Pethe</td>
                      <td>+91 1234567890</td>
                      <td>Pass@123</td>
                      <td className="text-red">₹4200</td>
                      <td>
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                        <td className="border-none-holder">
                        <div className="action-holder">
                          <div
                            className="icon-holder"
                            onClick={() => setModalShow(true)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalAddShow(true)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </div>

                          <div
                            className="icon-holder"
                            onClick={() => setModalDeleteShow(true)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Edit_User_Modal show={modalShow} onHide={() => setModalShow(false)} />

      <Add_Points_Modal
        show={modalAddShow}
        onHide={() => setModalAddShow(false)}
      />

      <Delete_Points_Modal
        show={modalDeleteShow}
        onHide={() => setModalDeleteShow(false)}
      />
    </>
  );
}

export default Banner_View_List;

import React, { useContext, useEffect, useState } from "react";
// import "./Edit_User_Modal.css";
import { Button, Form, Modal } from "react-bootstrap";
import Modal_Heading from "../../Common_Component/Modal_Heading/Modal_Heading";
import Edit_User_Modal_Frame from "../../Common_Component/Common_Modal/Edit_User_Modal/Edit_User_Modal_Frame/Edit_User_Modal_Frame";
import { EditAdd, SingleData } from "../../../utils/apis/master/home_banner/home_banner";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";

function Edit(props) {

  const id = props.show;

  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const {
    IMG_URL
  } = useContext(Context)

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();
      DataToSend.append("name", data?.name);
      DataToSend.append("sub_title", data?.sub_title);
      DataToSend.append("description", data?.description);
      if (data?.image && data?.image[0]) {
        DataToSend.append("image", data.image[0]);  // Append the image file
      }
      const response = await EditAdd(id, DataToSend);
      if (response?.success) {
        // await setShowModal({ code: response.code, message: response.message });
        setTimeout(() => {
          // setShowModal(0);
          props.onHide();
        }, 1000);
      } else {
        // await setShowModal({ code: response.code, message: response.message });
      }

    } catch (error) {
      console.log(error);
    }
  };

  const GetEditData = async () => {
    const response = await SingleData(id);
    reset(response?.data);
  };
  useEffect(() => {
    GetEditData();
  }, [id]);

  console.log("idididi", id);


  return (
    <>
      <section>
        <Modal
          {...props}
          className="Edit_User_Modal modal_holder"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal_Heading modalHeading={"Edit Home Banner"} />
          <div className="modal-body-holder">
            <Edit_User_Modal_Frame />
            <Modal.Body>
              <div className="main-form-holder">
                <Form className="form-holder" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-12 col-sm-12 mt-3">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Heading*</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="name"
                          placeholder="Heading"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                          })}
                        />
                        {errors.name && (
                          <span className="text-danger">{errors.name.message}</span>
                        )}
                      </Form.Group>
                    </div>

                    <div className="col-md-12 col-sm-12 mt-3">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Sub Title</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="sub_title"
                          placeholder="Sub Title"
                          className={classNames("", {
                            "is-invalid": errors?.sub_title,
                          })}
                          {...register("sub_title", {
                            // required: "Sub Title is required",
                          })}
                        />
                        {errors.sub_title && (
                          <span className="text-danger">{errors.sub_title.message}</span>
                        )}
                      </Form.Group>
                    </div>

                    <div className="col-md-12 col-sm-12 mt-3">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="description"
                          placeholder="Description"
                          className={classNames("", {
                            "is-invalid": errors?.description,
                          })}
                          {...register("description", {
                            // required: "Description is required",
                          })}
                        />
                        {errors.description && (
                          <span className="text-danger">{errors.description.message}</span>
                        )}
                      </Form.Group>
                    </div>

                    <div className="col-md-12 col-sm-12 mt-3">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Image*</Form.Label>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.image,
                          })}
                          type="file"
                          {...register("image", {
                            // required: "Image is required",
                          })}
                          accept=".jpg, .jpeg, .png"
                        />
                        {errors.image && (
                          <span className="text-danger">{errors.image.message}</span>
                        )}
                      </Form.Group>
                    </div>

                    <div className="col-md-12 col-sm-12 mt-3">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Image Preview</Form.Label>
                        {typeof getValues("image") == "string" ? (
                          <div
                            className="image-preview-container mt-3"

                          >
                            <img
                              src={IMG_URL + getValues("image")}
                              alt="Preview"
                              className="image-preview"
                              style={{ width: "648px", height: "198px" }}
                            />
                          </div>
                        ) : (
                          imageFile &&
                          imageFile?.length > 0 && (
                            <div
                              className="image-preview-container mt-3"

                            >
                              <img
                                // src={URL.createObjectURL(getValues("image")[0])}
                                src={URL?.createObjectURL(imageFile[0])}
                                alt="Preview"
                                className="image-preview"
                                style={{ width: "648px", height: "198px" }}
                              />
                            </div>
                          )
                        )}
                      </Form.Group>
                    </div>

                  </div>
                </Form>

                <div className="modal-footer-holder">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6 ">
                      <Button className="search-btn" onClick={() => { props.onHide(); reset(); }}>
                        Cancel
                      </Button>
                    </div>

                    <div className="col-md-6 col-sm-6 col-6">
                      <Button
                        className="reset-btn "
                        type="button"
                        onClick={handleSubmit(onSubmit)}>
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </section>
    </>
  );
}

export default Edit;

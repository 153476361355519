import React, { useContext, useEffect, useState } from "react";
import Add from "./Add"
import Edit from "./Edit";
import Delete_Points_Modal from "../../Common_Component/Common_Modal/Delete_Points_Modal/Delete_Points_Modal";
import { Button, Form, Table } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faMinusCircle,
  faPenToSquare,
  faPlay,
  faPlus,
  faRefresh,
  faRotateRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../../utils/context";
import { DataList, DeleteData, ChangeStatus } from "../../../utils/apis/master/country/country";
import { Per_Page_Dropdown } from "../../../utils/common";

const Tables = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalEditShow, setModalEditShow] = useState(0);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "black" : "",
      color: state.isFocused || state.isSelected ? "white" : "white",
      cursor: "pointer",
    }),
  };

  // const {
  //   // ErrorNotify,
  //   // Per_Page_Dropdown,
  //   // isAllow,
  // } = useContext(AppContext);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [showoff, setShowoff] = useState(false);

  const getDataAll = async () => {
    const response = await DataList(currentPage, perPage, search);
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);

    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, modalShow, modalEditShow]);

  const StatusChange = async (id) => {
    const response = await ChangeStatus(id);
    setChangeStatus(response);
  };

  const handleDeleteRecord = async () => {
    setModalDeleteShow(false);
    if (recordToDeleteId) {
      const response = await DeleteData(recordToDeleteId);
      setRecordToDeleteId(null);
      setChangeStatus(response);
    }
  };


  return (
    <>
      <section className="View_List">
        {/* <Heading_Holder headingText={"Banner List"} /> */}

        <div className="search-bar-holder mt-4  mb-3">
          <Form>
            <div className="row">
              <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-5  col-holder">
                <Button type="button" className="search-btn" onClick={() => setModalShow(true)}>
                  Add
                </Button>
              </div>

              <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-5    ">
                <Form.Group className="form-field" controlId="formBasicEmail">
                  <Form.Control type="text" placeholder="Country Name" onChange={(e) => setSearch(e.target.value)} value={search} />
                </Form.Group>
              </div>

              <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-5  col-holder">
                <Button type="button" className="search-btn" onClick={getDataAll}>
                  Search
                </Button>
              </div>

              <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-5   col-holder">
                <Button type="button" className="reset-btn"
                  onClick={() => {
                    setSearch("");
                    setReset(!reset);
                  }}
                >
                  Reset
                </Button>
              </div>
              <div className="col-lg-4 col-md-12 col-12">
                <div className="d-flex ms-3">
                  <div className="show me-2">
                    <p style={{ color: "white" }} className="show mt-1">Show</p>
                  </div>
                  <div className="number me-2">
                    <select
                      className="form-select form-select-sm"
                      aria-label=".form-select-sm example"
                      onChange={(e) => setperPage(e.target.value)}
                    >
                      {option?.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="entries">
                    <p style={{ color: "white" }} className="show mt-1">entries</p>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-6 col-sm-2 col-12">
                  <div className="sowing me-2">
                    <p style={{ color: "white" }}
                      className="show mt-0">{`Showing ${Math.min(
                        (currentPage - 1) * perPage + 1
                      )} to ${Math.min(
                        currentPage * perPage,
                        data?.data?.totalEntries
                      )} of ${data?.data?.totalEntries} entries`}</p>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div >
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="user-list-table">
              {/* <Banner_List_Table /> */}

              <div className="data-table-holder">
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Name</th>

                      <th>Status</th>
                      <th className="border-none-holder">Action</th>
                    </tr>
                  </thead>

                  <tbody>

                    {data?.data?.data?.map((d, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{d?.name}</td>
                          <td>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={d.status}
                                // disabled={!isAllow?.includes(43)}
                                onChange={() => {
                                  StatusChange(d.id);
                                }}
                                id={`flexSwitchCheckDefault${d?.id}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`flexSwitchCheckDefault${d?.id}`}
                              >
                                {d.status ? "Active" : "Inactive"}
                              </label>
                            </div>
                          </td>
                          <td className="border-none-holder">
                            <div className="action-holder">
                              <div
                                className="icon-holder"
                                onClick={() => setModalEditShow(d?.id)}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </div>

                              <div
                                className="icon-holder"
                                onClick={() => {
                                  setRecordToDeleteId(d?.id);
                                  setModalDeleteShow(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}

                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </section >



      <Add show={modalShow} onHide={() => setModalShow(false)} />

      <Edit
        show={modalEditShow}
        onHide={() => setModalEditShow(false)}
      />

      <Delete_Points_Modal
        show={modalDeleteShow}
        handleDeleteRecord={handleDeleteRecord}
        onHide={() => setModalDeleteShow(false)}
      />
    </>
  );
}

export default Tables;

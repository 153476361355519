import React from "react";
import "./Total_Amount_info.css";
import Border_div from "../../Common_Component/Border_div/Border_div";

function Total_Amount_info() {
  return (
    <>
      <section className="Profile_info Total_Amount_info">
        <div className="row  info-row">
       
          <div className="col-xxl-10 col-lg-9 col-sm-6  col-12  ">
            <div className="heading-holder">
              <h3>Name: kumar11_66</h3>
              <div className="info-text-holder">
                <p>Email : kumar@gmail.com</p>
                <span className="vr mx-3"></span>
                <p>Contact No. : +91 3446738992</p>
              </div>
            </div>
          </div>

          <div className="col-xxl-2 col-lg-3 col-sm-6 col-12  ">
            <div className="row count-info-holder ">
              <div className="text-holder">
                <div>
                  <h3>Total Bet Amount</h3>

                  <Border_div divText={".500/-"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Total_Amount_info;

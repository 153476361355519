import React from "react";
import "./Border_div.css";

function Border_div({ divText }) {
  return (
    <>
      <section className="Border_div">
        <div className="sign-up-btn" type="submit">
        ₹ {divText}
        </div>
      </section>
    </>
  );
}

export default Border_div;

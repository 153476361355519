import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { Nav, Accordion } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown, faAnglesRight } from "@fortawesome/free-solid-svg-icons";

function Sidebar() {
  const [activeLink, setActiveLink] = useState("/dashboard"); // Set initial active link

  const handleNavClick = (path) => {
    setActiveLink(path);
  };

  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  });

  return (
    <section className="Sidebar">
      <Nav className="sidebar-link-holder">
        {/* Master Link */}


        {/* User Link */}
        <Nav.Link
          className={activeLink === "/view-list" ? "active" : ""}
          onClick={() => handleNavClick("/view-list")}
          as={Link}
          to={"/view-list"}
        >
          <FontAwesomeIcon icon={faAnglesRight} className="me-2" /> User
        </Nav.Link>

        {/* View All Played Game Link */}
        <Nav.Link
          className={activeLink === "/view-played-game" ? "active" : ""}
          onClick={() => handleNavClick("/view-played-game")}
          as={Link}
          to={"/view-played-game"}
        >
          <FontAwesomeIcon icon={faAnglesRight} className="me-2" /> View All
          Played Game
        </Nav.Link>

        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header onClick={() => handleNavClick("/master")}>
              <FontAwesomeIcon icon={faAnglesRight} className="me-2" />
              Master
            </Accordion.Header>
            <Accordion.Body>
              <Nav.Link
                className={activeLink === "/country" ? "active" : ""}
                onClick={() => handleNavClick("/country")}
                as={Link}
                to={"/country"}
              >
                Country
              </Nav.Link>
              <Nav.Link
                className={activeLink === "/state" ? "active" : ""}
                onClick={() => handleNavClick("/state")}
                as={Link}
                to={"/state"}
              >
                State
              </Nav.Link>
              <Nav.Link
                className={activeLink === "/city" ? "active" : ""}
                onClick={() => handleNavClick("/city")}
                as={Link}
                to={"/city"}
              >
                City
              </Nav.Link>
              <Nav.Link
                className={activeLink === "/pincode" ? "active" : ""}
                onClick={() => handleNavClick("/pincode")}
                as={Link}
                to={"/pincode"}
              >
                Pincode
              </Nav.Link>
              <Nav.Link
                className={activeLink === "/currency" ? "active" : ""}
                onClick={() => handleNavClick("/currency")}
                as={Link}
                to={"/currency"}
              >
                Currency
              </Nav.Link>
              <Nav.Link
                className={activeLink === "/home_banner" ? "active" : ""}
                onClick={() => handleNavClick("/home_banner")}
                as={Link}
                to={"/home_banner"}
              >
                Home Banner
              </Nav.Link>

            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion>
          <Accordion.Item eventKey="1">
            <Accordion.Header onClick={() => handleNavClick("/app_setup")}>
              <FontAwesomeIcon icon={faAnglesRight} className="me-2" />
              App Setup
            </Accordion.Header>
            <Accordion.Body>
              <Nav.Link
                className={activeLink === "/social-media" ? "active" : ""}
                onClick={() => handleNavClick("/social-media")}
                as={Link}
                to={"/social-media"}
              >
                Social Media
              </Nav.Link>


            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

      </Nav>
    </section>
  );
}

export default Sidebar;

import React from "react";
import "./Add_Points_Modal.css"
import { Button, Form, Modal } from "react-bootstrap";
import Modal_Heading from "../../Modal_Heading/Modal_Heading";
import Add_Points_Modal_Frame from "./Add_Points_Modal_Frame/Add_Points_Modal_Frame";

function Add_Points_Modal(props) {
  return (
    <>
      <section>
        <Modal
          {...props}
          className=" Add_Points_Modal modal_holder"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal_Heading modalHeading={"Add Points"} />
          <div className="modal-body-holder">
            <Add_Points_Modal_Frame />
            <Modal.Body>
              <div className="main-form-holder">
                <Form className="form-holder">
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Payment</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Payment"
                        />
                      </Form.Group>
                    </div>

                 
                  </div>
                </Form>

                <div className="modal-footer-holder">
                  <div className="row">
                    <div className=" col-md-6 col-sm-6 col-6">
                      <Button className="search-btn" onClick={props.onHide}>
                        Cancel
                      </Button>
                    </div>

                    <div className=" col-md-6 col-sm-6 col-6">
                      <Button className="reset-btn " type="button">
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </section>
    </>
  );
}

export default Add_Points_Modal;

import React from "react";
import Total_Amount_info from "./Total_Amount_info/Total_Amount_info";
import Total_Amount_table from "./Total_Amount_table/Total_Amount_table";

function Total_Amount() {
  return (
    <>
      <section className="Profile">
        <div className="info-card-holder">
          <Total_Amount_info />
          <Total_Amount_table />
        </div>
      </section>
    </>
  );
}

export default Total_Amount;

import React from "react";
import Buckets from "../Common_Component/Buckets/Buckets";
import Table_Heading from "../Common_Component/Table_Heading/Table_Heading";
import New_User_Table from "./New_User_Table/New_User_Table";
import { Form, Table } from "react-bootstrap";
import Border_btn from "../Common_Component/Border_btn/Border_btn";

function Customer_Management() {
  const bucketData = [
    { BucketText: "Today's Total Play", divText: "23456" },
    { BucketText: "Today's Winner List", divText: "23456" },
    { BucketText: "Today's Added Money", divText: "23456" },
    { BucketText: "Today's Wallet Money", divText: "23456" },
    { BucketText: "Today's Withdraw Money", divText: "23456" },
  ];

  return (
    <section className="Dashboard">
      <div className="row mb-5">
        {bucketData.map((bucket, index) => (
          <div
            key={index}
            className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-4  "
          >
            <div className="buckets-holder">
              <Buckets
                BucketText={bucket.BucketText}
                divText={bucket.divText}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="row">
        <div className="col-xl-6 col-lg-12 col-md-12">
          <div className="table-holder">
            <Table_Heading tableHeading={"New User"} />

            <div className="main-table-holder">
              <New_User_Table />
              <div className="data-table-holder">
                <Table responsive className="table-striped ">
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Name</th>
                      <th>Mobile Number</th>
                      <th className="border-none-holder">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>01</td>
                      <td>Rimsha Pethe</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                    </tr>
                    <tr>
                      <td>02</td>
                      <td>Paresh M</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                    </tr>
                    <tr>
                      <td>03</td>
                      <td>Mandar J</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                    </tr>

                    <tr>
                      <td>04</td>
                      <td>Praful P</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                    </tr>

                    <tr>
                      <td>05</td>
                      <td>Swapnil Patil</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                    </tr>

                    <tr>
                      <td>05</td>
                      <td>Swapnil Patil</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                    </tr>

                    <tr>
                      <td>05</td>
                      <td>Swapnil Patil</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                    </tr>

                    <tr>
                      <td>05</td>
                      <td>Swapnil Patil</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                    </tr>

                    <tr>
                      <td>05</td>
                      <td>Swapnil Patil</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                    </tr>

                    <tr>
                      <td>05</td>
                      <td>Swapnil Patil</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                    </tr>

                    <tr>
                      <td>05</td>
                      <td>Swapnil Patil</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">
                        <Form>
                          <Form.Check type="switch" id="custom-switch" />
                        </Form>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>

        <div className=" col-xl-6 col-lg-12 col-md-12">
          <div className="table-holder">
            <Table_Heading tableHeading={"Todays Played"} />

            <div className="main-table-holder">
              <New_User_Table />

              <div className="data-table-holder">
                <Table responsive className="table-striped ">
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Name</th>
                      <th>Mobile Number</th>
                      <th className="border-none-holder">Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>01</td>
                      <td>Rimsha Pethe</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">
                      ₹. 100/-
                      </td>
                    </tr>
                    <tr>
                      <td>02</td>
                      <td>Paresh M</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">₹. 100/-</td>
                    </tr>
                    <tr>
                      <td>03</td>
                      <td>Mandar J</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">₹. 100/-</td>
                    </tr>

                    <tr>
                      <td>04</td>
                      <td>Praful P</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">₹. 100/-</td>
                    </tr>

                    <tr>
                      <td>05</td>
                      <td>Swapnil Patil</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">₹. 100/-</td>
                    </tr>

                    <tr>
                      <td>05</td>
                      <td>Swapnil Patil</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">₹. 100/-</td>
                    </tr>

                    <tr>
                      <td>05</td>
                      <td>Swapnil Patil</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">₹. 100/-</td>
                    </tr>

                    <tr>
                      <td>05</td>
                      <td>Swapnil Patil</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">₹. 100/-</td>
                    </tr>

                    <tr>
                      <td>05</td>
                      <td>Swapnil Patil</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">₹. 100/-</td>
                    </tr>

                    <tr>
                      <td>05</td>
                      <td>Swapnil Patil</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">₹. 100/-</td>
                    </tr>

                    <tr>
                      <td>05</td>
                      <td>Swapnil Patil</td>
                      <td>+91 1234567890</td>
                      <td className="border-none-holder">₹. 100/-</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="view-all-btn">
        <Border_btn btnText={"View All"} baseLink={"/view-list"} />
      </div>
    </section>
  );
}

export default Customer_Management;

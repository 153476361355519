import {
    GET,
    POST,
    PUT,
    EditStatus,
    DELETE,
    DownloadExcel,
} from "../../../api";

export const DataList = async (currentPage, perPage, search) => {
    try {
        return await GET(`/master/currency?page=${currentPage || 1}&per_page=${perPage || 10}&term=${search || ""}`);
    } catch (error) {
        console.log(error);
    }
};

export const SingleData = async (id) => {
    try {
        return await GET(`/master/currency/${id}`);
    } catch (error) {
        console.log(error);
    }
};

export const DataAdd = async (data) => {
    try {
        return await POST(`/master/currency`, data);
    } catch (error) {
        console.log(error);
    }
};

export const EditAdd = async (id, data) => {
    try {
        return await POST(`/master/currency/${id}`, data);
    } catch (error) {
        console.log(error);
    }
};

export const ChangeStatus = async (id) => {
    try {
        return await EditStatus(`/master/currency/${id}`);
    } catch (error) {
        console.log(error);
    }
};

export const DeleteData = async (id) => {
    try {
        return await DELETE(`/master/currency/${id}`);
    } catch (error) {
        console.log(error);
    }
};

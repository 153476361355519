import React from 'react'
import "./Add_Points_Modal_Frame.css"

function Add_Points_Modal_Frame() {
  return (
    <>
        <section className='Add_Points_Modal_Frame'>
        <div className="table-frame-holder">
          <img
            className="table-frame-img"
            src={process.env.PUBLIC_URL + "/Frame/table-frame.png"}
            alt="table-frame-img"
          />
        </div>
        </section>
    </>
  )
}

export default Add_Points_Modal_Frame
import React from "react";
import "./Withdraw_History_table.css";
import Profile_Frame from "../Profile_Frame/Profile_Frame";
import { Form, Table } from "react-bootstrap";

function Withdraw_History_table() {
  return (
    <>
      <div className="Withdraw_History_table profile_tab_table">
        <Profile_Frame />

        <div className="data-table-holder">
          <Table responsive className="table-striped ">
            <thead>
              <tr>
                <th>User Id </th>
                <th>Type </th>
                <th>Account No.</th>
                <th>IDFC</th>
                <th>Upi Id </th>
                <th>Amount </th>
                <th className="border-none-holder">Date</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="border-none-holder"></td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="border-none-holder"></td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="border-none-holder"></td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="border-none-holder"></td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="border-none-holder"></td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default Withdraw_History_table;

import React from "react";
import "./Profile_Tab.css";
import { Nav, Tab } from "react-bootstrap";
import Withdraw_History_table from "../Withdraw_History/Withdraw_History_table";
import Wallet_History from "../Wallet_History/Wallet_History";
import Bet_History from "../Bet_History/Bet_History";
import Transaction from "../Transaction/Transaction";

function Profile_Tab() {
  return (
    <>
      <div className="Profile_Tab">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="Withdraw_History"
        >
          <div className="row">
            <div className="col-md-12">
              <Nav variant="pills" className="tab-row" fill>
                <Nav.Item>
                  <Nav.Link eventKey="Withdraw_History">
                    Withdraw History
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="Wallet_History">Wallet History</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="Bet_History">Bet History</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="Transaction">Transaction</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-md-12">
              <Tab.Content>
                <Tab.Pane eventKey="Withdraw_History">
                  <Withdraw_History_table />{" "}
                </Tab.Pane>
                <Tab.Pane eventKey="Wallet_History">
                  <Wallet_History />
                </Tab.Pane>
                <Tab.Pane eventKey="Bet_History">
                  <Bet_History />
                </Tab.Pane>
                <Tab.Pane eventKey="Transaction">
                  <Transaction />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
}

export default Profile_Tab;

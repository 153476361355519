import {
  GET,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";


export const getAllCountries = async () => {
  return await GET("/allcountry");
}

export const getAllStates = async (id) => {
  return await GET(`/allstate/${id}`);
}

export const getAllCities = async (id) => {
  return await GET(`/allcity/${id}`);
}

export const getAllPincodes = async (id) => {
  return await GET(`/allpincodes/${id}`);
}

export const getAllCurrencies = async () => {
  return await GET(`/allcurrency`);
}
import React from 'react'
import "./Table_Heading.css"

function Table_Heading({tableHeading}) {
  return (
   <>
    <section className='Table_Heading'>
        <h3 className='font-use'>{tableHeading}</h3>
    </section>
   </>
  )
}

export default Table_Heading
import React, { useContext, useEffect, useState } from "react";
// import "./Edit_User_Modal.css";
import { Button, Form, Modal } from "react-bootstrap";
import Modal_Heading from "../../Common_Component/Modal_Heading/Modal_Heading";
import Edit_User_Modal_Frame from "../../Common_Component/Common_Modal/Edit_User_Modal/Edit_User_Modal_Frame/Edit_User_Modal_Frame";
import { EditAdd, SingleData } from "../../../utils/apis/master/pincode/pincode";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Select2Data } from "../../../utils/common";
import Select from "react-select";
import { getAllCountries, getAllStates, getAllCities } from "../../../utils/apis/master/Master";

function Edit(props) {

  const id = props.show;

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [countries, setCountries] = useState([]);
  const [states, setState] = useState([]);
  const [cities, setCities] = useState([]);

  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();
      DataToSend.append("country_id", data?.country_id?.value);
      DataToSend.append("state_id", data?.state_id?.value);
      DataToSend.append("city_id", data?.city_id?.value);
      DataToSend.append("name", data?.name);
      const response = await EditAdd(id, DataToSend);
      if (response?.success) {
        // await setShowModal({ code: response.code, message: response.message });
        setTimeout(() => {
          // setShowModal(0);
          props.onHide();
        }, 1000);
      } else {
        // await setShowModal({ code: response.code, message: response.message });
      }

    } catch (error) {
      console.log(error);
    }
  };

  const GetEditData = async () => {
    const response = await SingleData(id);
    reset(response?.data);
    GetAllStates(response?.data?.country_id?.value);
    GetAllCity(response?.data?.state_id?.value);
  };
  useEffect(() => {
    GetEditData();
  }, [id]);

  const GetAllCountry = async () => {
    const response = await getAllCountries();
    if (response?.success) {
      setCountries(await Select2Data(response?.data, "country_id"));
    }
  };

  const GetAllStates = async (id) => {
    const response = await getAllStates(id);
    if (response?.success) {
      setState(await Select2Data(response?.data, "state_id"));
    }
  };

  const GetAllCity = async (id) => {
    const response = await getAllCities(id);
    if (response?.success) {
      setCities(await Select2Data(response?.data, "city_id"));
    }
  };

  useEffect(() => {
    GetAllCountry();
  }, []);

  console.log("idididi", id);


  return (
    <>
      <section>
        <Modal
          {...props}
          className="Edit_User_Modal modal_holder"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal_Heading modalHeading={"Edit Pincode"} />
          <div className="modal-body-holder">
            <Edit_User_Modal_Frame />
            <Modal.Body>
              <div className="main-form-holder">
                <Form className="form-holder" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">

                    <div className="col-md-12 col-sm-12 mt-3">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Country*</Form.Label>
                        <Controller
                          name="country_id" // name of the field
                          {...register("country_id", {
                            required: "Select Country",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.country_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={countries}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption.value); // Update Controller's value
                                GetAllStates(selectedOption.value);
                                setValue("country_id", selectedOption);
                                setValue("state_id", null);
                              }}
                            />
                          )}
                        />
                        {errors.country_id && (
                          <span className="text-danger">
                            {errors.country_id.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>

                    <div className="col-md-12 col-sm-12 mt-3">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>State*</Form.Label>
                        <Controller
                          name="state_id" // name of the field
                          {...register("state_id", {
                            required: "Select State",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.state_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={states}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption.value); // Update Controller's value
                                GetAllCity(selectedOption.value);
                                setValue("state_id", selectedOption);
                                setValue("city_id", null);
                              }}
                            />
                          )}
                        />
                        {errors.state_id && (
                          <span className="text-danger">
                            {errors.state_id.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>

                    <div className="col-md-12 col-sm-12 mt-3">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>City*</Form.Label>
                        <Controller
                          name="city_id" // name of the field
                          {...register("city_id", {
                            required: "Select City",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.city_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={cities}

                            />
                          )}
                        />
                        {errors.city_id && (
                          <span className="text-danger">
                            {errors.city_id.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>

                    <div className="col-md-12 col-sm-12 mt-3">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Pincode*</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Pincode"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                            pattern: {
                              value: /^\d{6}$/, // Ensure only 6 digits
                              message: "Please enter exactly 6 digits",
                            }
                          })}
                          onKeyDown={(e) => {
                            // Prevent non-numeric input
                            if (!/^\d$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
                              e.preventDefault();
                            }

                            // Prevent input if more than 6 digits are entered
                            if (e.target.value.length >= 6 && e.key !== "Backspace" && e.key !== "Delete") {
                              e.preventDefault();
                            }
                          }}
                        />
                        {errors.name && (
                          <span className="text-danger">{errors.name.message}</span>
                        )}
                      </Form.Group>
                    </div>


                  </div>
                </Form>

                <div className="modal-footer-holder">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6 ">
                      <Button className="search-btn" onClick={() => { props.onHide(); reset(); }}>
                        Cancel
                      </Button>
                    </div>

                    <div className="col-md-6 col-sm-6 col-6">
                      <Button
                        className="reset-btn "
                        type="button"
                        onClick={handleSubmit(onSubmit)}>
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </section>
    </>
  );
}

export default Edit;

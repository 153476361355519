import React from "react";
import "./Edit_User_Modal.css";
import { Button, Form, Modal } from "react-bootstrap";
import Modal_Heading from "../../Modal_Heading/Modal_Heading";
import Edit_User_Modal_Frame from "./Edit_User_Modal_Frame/Edit_User_Modal_Frame";

function Edit_User_Modal(props) {
  return (
    <>
      <section>
        <Modal
          {...props}
          className="Edit_User_Modal modal_holder"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal_Heading modalHeading={"Edit User"} />
          <div className="modal-body-holder">
            <Edit_User_Modal_Frame />
            <Modal.Body>
              <div className="main-form-holder">
                <Form className="form-holder">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>User Name*</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter User Name"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-6">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Mobile Number*</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Mobile Number"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-6">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Google Pay*</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Google Pay"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-6">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Phone pay*</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Phone pay"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-6">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Password"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-6">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter Confirm Password"
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Form>

                <div className="modal-footer-holder">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6 ">
                      <Button className="search-btn" onClick={props.onHide}>
                        Cancel
                      </Button>
                    </div>

                    <div className="col-md-6 col-sm-6 col-6">
                      <Button className="reset-btn " type="button">
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </section>
    </>
  );
}

export default Edit_User_Modal;

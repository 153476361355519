import React from "react";
import "./Border_btn.css";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Border_btn({ btnText, baseLink }) {
  return (
    <>
      <Link to={baseLink}>
        <section className="Border_btn">
          <Button className="sign-up-btn font-use" type="submit">
            {btnText}
            <span>
              <FontAwesomeIcon icon={faCircleArrowRight} />
            </span>
          </Button>
        </section>
      </Link>
    </>
  );
}

export default Border_btn;

import React, { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "./index.css";
import "./Component/Common_Component/Common_Modal/Common_Modal.css";
import "./Component/Common_Component/Common_Css/Table/Table.css";
import Login from "./Component/Login/Login";
import Dashboard from "./Component/Dashboard/Dashboard";
import Customer_Management from "./Component/Customer_Management/Customer_Management";
import View_Played_Game from "./Component/View_Played_Game/View_Played_Game";
import Header from "./Component/Header/Header";
import View_List from "./Component/Dashboard/View_List/View_List";
import Sidebar from "./Component/Sidebar/Sidebar";
import Profile from "./Component/Profile/Profile";
import Total_Amount from "./Component/Total_Amount/Total_Amount";
import Profile_data_List from "./Component/Profile_data_List/Profile_data_List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Banner_View_List from "./Component/Master/Banner/Banner_View_List/Banner_View_List";
import Country from "./Component/Master/Country/Table";
import State from "./Component/Master/State/Table";
import City from "./Component/Master/City/Table";
import Pincode from "./Component/Master/Pincode/Table";
import Currency from "./Component/Master/Currency/Table";
import HomeBanner from "./Component/Master/HomeBanner/Table";
import SocialMedia from "./Component/Master/SocialMedia/Table";

import MiddelWare from "./Middleware";
import { IDS } from "./utils/common";
import { useContext } from "react";
import { Context } from "./utils/context";
import { useEffect } from "react";

function App() {
  // const location = useLocation();
  // const isLoginPage = location.pathname === "/";
  // const [isSidebarOpen, setSidebarOpen] = useState(false);

  // const toggleSidebar = () => {
  //   setSidebarOpen(!isSidebarOpen);
  // };

  const location = useLocation();
  const { isSidebarOpen, isAllow, toggleSidebar, signin } = useContext(Context);
  const [headerText, setHeaderText] = useState(location.pathname);

  const isLoginPage = location.pathname === "/";

  // console.log(isAllow, "isAllow");

  useEffect(() => {
    setHeaderText(location.pathname);
  });

  const [urls, setUrls] = useState([]);
  useEffect(() => {
    setUrls([
      {
        path: "/",
        element: <Login />,
        status: true,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
        status: true,
      },
      {
        path: "/customer-management",
        element: <Customer_Management />,
        status: true,
      },
      {
        path: "/view-played-game",
        element: <View_Played_Game />,
        status: true,
      },
      {
        path: "/view-list",
        element: <View_List />,
        status: true,
      },
      {
        path: "/banner",
        element: <Banner_View_List />,
        status: true,
      },
      {
        path: "/profile",
        element: <Profile />,
        status: true,
      },
      {
        path: "/total-amount",
        element: <Total_Amount />,
        status: true,
        // status: isAllow?.includes(IDS.Role.List),
      },

      {
        path: "/country",
        element: <Country />,
        status: true,
        // status: isAllow?.includes(IDS.Role.List),
      },
      {
        path: "/state",
        element: <State />,
        status: true,
        // status: isAllow?.includes(IDS.Role.List),
      },
      {
        path: "/city",
        element: <City />,
        status: true,
        // status: isAllow?.includes(IDS.Role.List),
      },
      {
        path: "/pincode",
        element: <Pincode />,
        status: true,
        // status: isAllow?.includes(IDS.Role.List),
      },
      {
        path: "/currency",
        element: <Currency />,
        status: true,
        // status: isAllow?.includes(IDS.Role.List),
      },
      {
        path: "/home_banner",
        element: <HomeBanner />,
        status: true,
        // status: isAllow?.includes(IDS.Role.List),
      },
      {
        path: "/social-media",
        element: <SocialMedia />,
        status: true,
        // status: isAllow?.includes(IDS.Role.List),
      },
    ]);
  }, [isAllow]);

  return (
    <>
      {isLoginPage && !signin ? (
        <Login />
      ) : (
        <>
          <Header />
          <section className="Dashboard">
            <div className="row me-0 ms-0">
              <div>
                <button
                  className="btn sidebar-toggle d-lg-none"
                  onClick={toggleSidebar}
                >
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className={isSidebarOpen ? "icon-rotate" : ""}
                  />
                </button>
              </div>

              <div
                className={`offcanvas-sidebar ${isSidebarOpen ? "open" : ""
                  } col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-8 ps-0`}
              >
                <div className="sidebar_holder">
                  <Sidebar />
                </div>
              </div>

              <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                <div className="main-content-holder">
                  <Routes>
                    {/* <Route
                    path="/dashboard"
                    element={<MiddelWare component={Dashboard} />}
                  /> */}

                    {urls?.map(
                      (url, index) =>
                        url.status && (
                          <Route
                            key={index}
                            path={url.path}
                            element={<MiddelWare component={url.element} />}
                          // element={url.element}
                          />
                        )
                    )}

                    {/* <Route path="/dashboard" element={<Dashboard />} />
                    <Route
                      path="/customer-management"
                      element={<Customer_Management />}
                    />
                    <Route
                      path="/view-played-game"
                      element={<View_Played_Game />}
                    />
                    <Route path="/view-list" element={<View_List />} />
                    <Route path="/banner" element={<Banner_View_List />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/total-amount" element={<Total_Amount />} />
                    <Route
                      path="/profile-data"
                      element={<Profile_data_List />}
                    /> */}
                  </Routes>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {/* {!isLoginPage && <Header />}
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes> */}

      {/* Render Dashboard section only if not on the login page */}
      {/* {!isLoginPage && (
        <section className="Dashboard">
          <div className="row me-0 ms-0">
            <div>
              <button
                className="btn sidebar-toggle d-lg-none"
                onClick={toggleSidebar}
              >
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className={isSidebarOpen ? "icon-rotate" : ""}
                />
              </button>
            </div>

            <div
              className={`offcanvas-sidebar ${
                isSidebarOpen ? "open" : ""
              } col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-8 ps-0`}
            >
              <div className="sidebar_holder">
                <Sidebar />
              </div>
            </div>

            <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
              <div className="main-content-holder">
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route
                    path="/customer-management"
                    element={<Customer_Management />}
                  />
                  <Route
                    path="/view-played-game"
                    element={<View_Played_Game />}
                  />
                  <Route path="/view-list" element={<View_List />} />
                  <Route path="/banner" element={<Banner_View_List />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/total-amount" element={<Total_Amount />} />
                  <Route path="/profile-data" element={<Profile_data_List />} />
                </Routes>
              </div>
            </div>
          </div>
        </section>
      )} */}
    </>
  );
}

export default App;

import React from "react";
import "./View_Played_Game.css";
import Heading_Holder from "../Common_Component/Heading_Holder/Heading_Holder";
import User_List_Table from "../Customer_Management/View_List/User_List_Table/User_List_Table";
import { Table } from "react-bootstrap";

function View_Played_Game() {
  return (
    <>
      <section className="View_List">
        <Heading_Holder headingText={"Played Today"} />

        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="user-list-table">
              <User_List_Table />

              <div className="data-table-holder">
                <Table responsive className="table-striped ">
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>User Name </th>
                      <th>Game Name</th>
                      <th>Bet Type</th>
                      <th>Bet Place </th>
                      <th className="border-none-holder">Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>01</td>
                      <td>Smita Lad</td>
                      <td>Funtarget</td>
                      <td>Pass@123</td>
                      <td>1200</td>
                      <td className="border-none-holder">₹ 1200</td>
                    </tr>

                    <tr>
                      <td>01</td>
                      <td>Smita Lad</td>
                      <td>Funtarget</td>
                      <td>Pass@123</td>
                      <td>1200</td>
                      <td className="border-none-holder">₹ 1200</td>
                    </tr>

                    <tr>
                      <td>01</td>
                      <td>Smita Lad</td>
                      <td>Funtarget</td>
                      <td>Pass@123</td>
                      <td>1200</td>
                      <td className="border-none-holder">₹ 1200</td>
                    </tr>

                    <tr>
                      <td>01</td>
                      <td>Smita Lad</td>
                      <td>Funtarget</td>
                      <td>Pass@123</td>
                      <td>1200</td>
                      <td className="border-none-holder">₹ 1200</td>
                    </tr>

                    <tr>
                      <td>01</td>
                      <td>Smita Lad</td>
                      <td>Funtarget</td>
                      <td>Pass@123</td>
                      <td>1200</td>
                      <td className="border-none-holder">₹ 1200</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default View_Played_Game;
